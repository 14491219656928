<template>
    <modal ref="modalTitulos" titulo="Título" tamano="modal-md" no-aceptar adicional="Añadir" @adicional="valid">
        <ValidationObserver ref="validacion">
            <div class="row mx-0 justify-center">
                <div class="col-10 mb-2">
                    <small class="pl-3 text-general">
                        Título
                    </small>
                    <ValidationProvider v-slot="{errors}" rules="required" name="Título">
                        <el-input v-model="title" placeholder="Título" class="w-100" size="small" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-10 mt-3 pl-4">
                    <p class="pl-4 text-general f-18 f-500 ml-3 mb-2">
                        Estilo
                    </p>
                    <div v-for="(style, index) in styles" :key="index" class="row mx-0 align-items-center mb-4">
                        <el-radio v-model="type" :label="style.id" class="radio-green mr-0">
                            <span class="text-white" />
                        </el-radio>
                        <div v-if="style.id == 21" class="col">
                            <p class="text-general f-600 f-19">
                                {{ style.label }}
                            </p>
                        </div>
                        <div v-if="style.id == 22" class="col f-19">
                            <p class="text-general f-600">
                                {{ style.label }}
                            </p>
                            <div class="bg-general" style="width:27px;height: 3px;" />
                        </div>
                        <div v-if="style.id == 23" class="col d-flex f-19">
                            <div class="space-title px-2 text-general f-600">
                                {{ style.label }}
                                <div class="bg-title" />
                            </div>
                            <div class="vertical-bar-01" />
                            <div class="vertical-bar-02" />
                            <div class="vertical-bar-03" />
                        </div>
                    </div>
                </div>
                <div class="col-10 pl-4 mt-1">
                    <p class="text-general f-20 pl-5 mb-3">
                        Alineacion
                    </p>
                    <div class="row mx-0">
                        <div class="col-auto d-flex px-0">
                            <button v-for="(p,index) in parameters" :id="p.id" :key="index" type="button" class="box-align-option cr-pointer mr-3 ml-1" :class="{'bg-general2':p.id == parameter}" @click="selectParameter(p.id)">
                                <i :class="p.icon" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Service from '~/services/landing/landing_sections'

export default {
    data(){
        return {
            modoActualizar: false,
            idSection: null,
            title: null,
            parameter: 21,
            type: 21
        }
    },

    computed:{
        idLanding(){
            return Number(this.$route.params.idLanding)
        },
        styles(){
            return [
                { id:21, label:'Título simple' },
                { id:22, label:'Título línea' },
                { id:23, label:'Título fondo' }
            ]
        },
        parameters(){
            return [
                { id:21, label:'Izquierda', icon:'icon-align-left f-17 text-general' },
                { id:22, label:'Centro', icon:'icon-align-center f-17 text-general' },
                { id:23, label:'Derecha', icon:'icon-align-right f-17 text-general' }
            ]
        },
    },

    methods: {
        toggle(bandera, idSection){
            if(!this.idLanding)return;

            if(bandera === 'editar'){
                this.modoActualizar = true;
                this.idSection = idSection;
                this.getTitle();
            }
            this.$refs.modalTitulos.toggle();
        },

        async getTitle(){
            try {
                const { data } = await Service.getElementsSection(this.idSection);
                this.title = data.section.nombre;
                this.parameter = data.section.parametro;
                this.type = data.section.tipo;

            } catch(e){
                this.error_catch(e)
            }
        },

        async valid(){
            if(!this.idLanding)return;

            if(this.modoActualizar){
                this.updateTitle();
            } else {
                this.postTitle();
            }
        },

        async postTitle(){
            try {
                const valid = await this.$refs.validacion.validate();
                if(!valid)return;

                const model = {
                    title: this.title,
                    type: this.type,
                    parameter: this.parameter,
                    idLanding: this.idLanding
                };

                const {data} = await Service.postTitle(model)
                this.$store.commit('landing/landing/setListenerUpdater', {value:'titulo', type: 'nuevaData', idSection: null});
                
                this.notificacion('Mensaje', data.mensaje, data.tipo)
                this.clear();
                this.$refs.modalTitulos.toggle();
            } catch(e){
                this.error_catch(e)
            }
        },

        async updateTitle(){
            try {
                const model = {
                    title: this.title,
                    type: this.type,
                    parameter: this.parameter,
                    idLanding: this.idLanding
                };

                const {data} = await Service.updateTitle(this.idSection, model)
                this.$emit('update');
                this.notificacion('Mensaje', data.mensaje, data.tipo)
                this.clear();
                this.$refs.modalTitulos.toggle();
            } catch(e){
                this.error_catch(e)
            }

        },
        selectParameter(id){
            this.parameter = id;
        },
        clear(){
            this.modoActualizar = false;
            this.idSection = null;
            this.title = null;
            this.parameter = 21;
            this.type = 21;
            this.$refs.validacion.reset();
        },
    }
}
</script>
<style lang="scss" scoped>
.space-title{
    height: 30px;
    position: relative;
    //background-color: rgb(216, 207, 244);
    display: flex;
    align-items: center;
    justify-content: center;
    .bg-title{
        background-color: var(--color-general);
        opacity: 0.2;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
    }
}
.vertical-bar{
        background-color: var(--color-general);
        opacity: 0.2;
        height: 30px;
        width: 10px;
        &-01{
            @extend .vertical-bar;
            margin-left: 5px;
            margin-right: 5px;
            /* right: -16px; */
        }
        &-02{
            @extend .vertical-bar;
            margin-right: 5px;
            /* right: -28px; */
        }
        &-03{
            @extend .vertical-bar;
            margin-right: 5px;
            /* right: -40px; */
        }
    }

.box-align-option{
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #DFE4E8;
    background-color: #FFFFFF;
    border-radius: 8px;
}

.newclass {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #DFE4E8;
    background-color: #b2b4b6;
    border-radius: 8px;
}
</style>
